import React, { useState } from 'react';

const InputField = ({ onMessageSend }) => {
  const [input, setInput] = useState('');

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleInputSubmit = (event) => {
    event.preventDefault();
    onMessageSend(input);
    setInput('');
  };

  return (
    <form onSubmit={handleInputSubmit}>
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        placeholder="Type your message here..."
      />
      <button type="submit">Send</button>
    </form>
  );
};

export default InputField;

