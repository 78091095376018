import React from 'react';

const ResetButton = ({ onReset }) => {
  return (
    <button onClick={onReset}>
      Reset context and start a fresh conversation
    </button>
  );
};

export default ResetButton;

