import './App.css';
import React from 'react';
import ChatWindow from './components/ChatWindow/ChatWindow';
import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <b>Hello! I'm your Xficient assistant ready to help you work xficiently.</b>
        </p>
        <ChatWindow />
      </header>
    </div>
  );
}

export default App;

