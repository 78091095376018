import React, { useContext, useState } from 'react';
import InputField from '../InputField/InputField';
import ResetButton from '../ResetButton/ResetButton';
import { askQuestion } from '../../callers/TransquestorCaller';

const ChatWindow = () => {
  const [messages, setMessages] = useState([]);

  const handleUserMessage = async (text) => {
    const message = {
      sender: 'You',
      text
    };
    setMessages((prevMessages) => [...prevMessages, message]);
    
    try {
      const serverResponse = await askQuestion(text);
      setMessages([...messages, message, { sender: 'Xf', text: serverResponse }]);
    } catch (error) {
      console.error("Error calling Transquestor service:", error);
    }
  };

  const handleReset = () => {
    setMessages([]);
  };

  return (
    <div>
      {messages.map((message, index) => (
        <p key={index}><b>{message.sender}:</b> {message.text}</p>
      ))}
      <InputField onMessageSend={handleUserMessage} />
      <br/>
      <ResetButton onReset={handleReset} />
    </div>
  );
};

export default ChatWindow;

