export function askQuestion(text) {
    // This is ultimately sourced from Aimilia/configstore - the server must load this into the environment
    const url = `https://${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}/api/transquestor`; //See c-29-chatquest-56
    //const url = `http://freya:8888/api/transquestor`;

    const urlParams = new URLSearchParams(window.location.search);
    const app  = urlParams.get('app');

    // The data we are going to send in our request
    const data = { 
      text: text,
      app: app
    };

    console.log(`Attempting to send request to ${url} with data:`, data);

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => resolve(data.text))
        .catch(error => reject(error));
    });
}

